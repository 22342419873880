import Decimal from 'decimal.js';

import { CurrencyUSD } from '@/graphql/scalars';
import { TenantDetails } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import {
  AssetValuationV2ValuationSource,
  LoggedTransferPurpose,
} from '@/types/schema';

import {
  EntityValuationHistoryPage_EntityFragment,
  EntityValuationHistoryPage_TransferFragment,
  EntityValuationHistoryPage_ValuationFragment,
} from './graphql/GetEntityValuationHistory.generated';

export interface ValuationRowData {
  id: string;
  updatedOn: Date;
  amount: CurrencyUSD;
  updatedBy: string;
}

export interface TransferRowData extends ValuationRowData {
  transferType: string;
  transferTypeDescription?: string;
  data: EntityValuationHistoryPage_TransferFragment;
  actionable: boolean;
}

export interface GiftRowData {
  id: string;
  loggedByUser: string;
  amount: CurrencyUSD;
  giftDate: Date;
  actionable: boolean;
}

enum TransferType {
  Contribution = 'Contribution',
  Distribution = 'Distribution',
  Gift = 'Gift',
  GratRemainederDistribution = 'GRAT remainder distribution',
}

export const mapTransferRowData = (
  transferRows: EntityValuationHistoryPage_TransferFragment[],
  entityId: string
): TransferRowData[] =>
  transferRows.map((row) => {
    let transferType: TransferType | null = null;
    let actionable = true;

    if (row.purpose === LoggedTransferPurpose.CharitableGift) {
      transferType = TransferType.Gift;
    } else if (row.receivingEntity?.id === entityId) {
      transferType = TransferType.Contribution;
    } else if (row.sourceEntity?.id === entityId) {
      transferType = TransferType.Distribution;
    } else if (
      row.purpose === LoggedTransferPurpose.GratRemainderDistribution
    ) {
      transferType = TransferType.GratRemainederDistribution;
    }

    let transferTypeDescription = '';
    if (transferType === TransferType.Contribution) {
      transferTypeDescription = row.friendlyContributionPurpose || '';
    } else if (transferType === TransferType.Distribution) {
      transferTypeDescription = row.friendlyDistributionPurpose || '';
    } else if (row.purpose === LoggedTransferPurpose.Other) {
      transferTypeDescription = row.otherPurposeDescription || '';
    }

    if (row.purpose === LoggedTransferPurpose.GratRemainderDistribution) {
      actionable = false;
    }

    return {
      id: row.id,
      updatedOn: row.transactionDate,
      updatedBy: row.user.displayName,
      amount: row.amount,
      transferType: transferType?.toString() || '',
      transferTypeDescription: transferTypeDescription || '',
      actionable,
      data: row,
    };
  });

const VALUATION_SOURCE_DISPLAY_NAMES: Record<
  AssetValuationV2ValuationSource,
  string
> = {
  [AssetValuationV2ValuationSource.Addepar]: 'Addepar',
  [AssetValuationV2ValuationSource.BlackDiamond]: 'Black Diamond',
  [AssetValuationV2ValuationSource.Orion]: 'Orion',
  [AssetValuationV2ValuationSource.CsvImport]: 'Bulk import',
  // Manual isn't used, it's just here for completeness; we should show the user's name instead
  [AssetValuationV2ValuationSource.Manual]: 'Manual',
};

export const mapValuationDataToRows = (
  valuationRows: EntityValuationHistoryPage_ValuationFragment[],
  integrationConfiguration: TenantDetails['integrationConfiguration']
): ValuationRowData[] => {
  return valuationRows.map((row) => {
    const updatedByCopy = (() => {
      if (row.valuationSource === AssetValuationV2ValuationSource.Manual) {
        return row.user?.displayName || '';
      }

      if (row.valuationSource === AssetValuationV2ValuationSource.CsvImport) {
        return (
          integrationConfiguration?.csvValuationImportName ??
          VALUATION_SOURCE_DISPLAY_NAMES[row.valuationSource]
        );
      }

      return VALUATION_SOURCE_DISPLAY_NAMES[row.valuationSource] || '';
    })();

    return {
      id: row.id,
      updatedOn: row.effectiveDate,
      amount: row.valuationValue || new Decimal(0),
      updatedBy: updatedByCopy,
    };
  });
};

export function entityHasBusinessOwnershipStakes(
  entity: EntityValuationHistoryPage_EntityFragment | undefined | null
) {
  if (!entity?.ownedOwnershipStakes) return false;
  return entity.ownedOwnershipStakes.length > 0;
}
